<template>
  <div class="registration-flow-item">
    <div class="flow-title" v-if="hasTitle || forceIcon">
      <slot name="title"></slot>
    </div>
    <div class="icon-container" :class="{'has-icon': hasIcon || forceIcon, 'active': active, 'done': done, 'no-input':noInput}">
      <slot name="icon"></slot>
    </div>
    <div class="flow-description" v-if="!forceIcon && hasDescription">
      <span>
        <slot name="description"></slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['active', 'noInput', 'done', 'skip', 'forceIcon'],
  computed: {
    hasIcon(){
      return this.$slots['icon'] ? true : false;
    },
    hasTitle(){
      return !!this.$slots['title']
    },
    hasDescription(){
      return !!this.$slots['description']
    }
  }
}
</script>
<style lang="scss" scoped>
  .registration-flow-item {
    position:relative;
    .flow-title{
      display:inline-block;
      position: absolute;
      font-size: 13px;
      text-align: center;
      color: #111111;
      margin-top: -2.5rem;
      width: 100px;
      margin-left: -30px;
    }
    .flow-description{
      position: absolute;
      display:flex;
      flex-direction: column;
      font-size: 13px;
      color: #111111;
      text-align: center;
      margin-top: .8rem;
      margin-left: -1.85rem;
      background: #EDF1F6;
      padding: .3rem .5rem .3rem .5rem;
      letter-spacing: 0.97px;
      width: 78px;
      height: 52px;
      .arrow-up {
        width: 0; 
        height: 0; 
        margin-top: -0.9rem;
        margin-bottom: 0.5rem;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #EDF1F6;
        align-self : center;
      }
    }

    .icon-container {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #CCCCCC;
      color: #6C6C6C;
      &.has-icon{
        width: 45px;
        height: 45px;
        text-align: center;
        font-size: 1.5rem;
        padding-top: 0.2rem;
      }
      &.no-input { 
        background: #CCCCCC;
        color: #6C6C6C;
      }
      &.active {
        background: #FF4500;
        color: #fff;
      }
      &.done {
        background: #CCCCCC;
        color: #6C6C6C;
      }
    }

  }
</style>