<template>
  <div class="registration-flow-container">
    <div class="flow">
      <FlowItem :active="!status.companyInfoRegistered || !status.usersRegistered" :done="status.companyInfoRegistered && status.usersRegistered">
        <template v-slot:title>
          {{$t('WIZARD.SALES_MANAGER_INFO')}}
        </template>
        <template v-slot:icon>
          <font-awesome-icon class="va-m" icon="landmark"></font-awesome-icon>
        </template>
      </FlowItem>

      <template v-if="status.spotSupplier === false">
          
        <div class="divider"></div>
        
        <FlowItem :active="
          !status.companyAddonRegistered||
          !status.categoryRegistered||
          !status.productNameRegistered||
          !status.deliverableRegistered||
          !status.verificationRegistered||
          !status.bankAccountRegistered" :done="true">
          <template v-slot:icon>
            <font-awesome-icon class="va-m" icon="building"></font-awesome-icon>
          </template>
          <template v-slot:title><br/>{{$t('WIZARD.DETAILED_INFO')}}</template>
        </FlowItem>
        
        <div class="divider"></div>
        
        <FlowItem :active="
          !status.companyAddonRegistered||
          !status.categoryRegistered||
          !status.productNameRegistered||
          !status.deliverableRegistered||
          !status.verificationRegistered||
          !status.bankAccountRegistered" :done="true">
          <template v-slot:icon>
            <font-awesome-icon class="va-m" icon="bullhorn"></font-awesome-icon>
          </template>
          <template v-slot:title><br/>{{$t('WIZARD.PR_INFO')}}</template>
        </FlowItem>

        <div class="divider"></div>

        <FlowItem :active="status.registrationCompleted" :done="status.buyerExaminationCompleted" no-input=true>
          <template v-slot:icon>
            <font-awesome-icon class="va-m" icon="eye"></font-awesome-icon>
          </template>
          <template v-slot:title>
            <template v-if="status.openSupplier">
              {{$t('WIZARD.OPEN_WAITING_CHECK')}}
            </template>
            <template v-else>
              <br/>{{$t('WIZARD.WATING_CHECK')}}
            </template>
          </template>
        </FlowItem>

        <div class="divider dashed double" v-if="status.openSupplier"></div>
        <div class="divider dashed double-next" v-if="status.openSupplier"></div>
        
        <div class='multi-step' v-if="status.openSupplier">
          <FlowItem :active="status.buyerExaminationCompleted" :done="status.addressVerified">
            <template v-slot:icon>
              <font-awesome-icon class="va-m" icon="clipboard-list"></font-awesome-icon>
            </template>
            <template v-slot:title>
              {{$t('WIZARD.STEP_ADDRESS_VERIFIED')}}
            </template>
          </FlowItem>
          <FlowItem :active="status.buyerExaminationCompleted" :done="status.contractSigned">
            <template v-slot:icon>
              <font-awesome-icon class="va-m" icon="fingerprint"></font-awesome-icon>
            </template>
            <template v-slot:title>
              <br/>
              {{$t('WIZARD.STEP_CONTRACT_SIGN')}}
            </template>
          </FlowItem>
        </div>

        <div class="divider double-next" v-if="status.openSupplier"></div>
        <div class="divider double-reverse" v-if="status.openSupplier"></div>
        <div class="divider dashed" v-if="!status.openSupplier"></div>
        
        <FlowItem :active="status.buyerExaminationCompleted" :done="status.contractSigned" v-if="!status.openSupplier">
          <template v-slot:icon>
            <font-awesome-icon class="va-m" icon="fingerprint"></font-awesome-icon>
          </template>
          <template v-slot:title>
            <br/>
            {{$t('WIZARD.STEP_CONTRACT_SIGN')}}
          </template>
        </FlowItem>


      </template>
      
      <div class="divider dashed"></div>

      <FlowItem :active="status.contractSigned && status.addressVerified" :done="status.completed" no-input=true>
        <template v-slot:icon>
          <font-awesome-icon class="va-m" icon="check"></font-awesome-icon>
        </template>
        <template v-slot:title>
          <br/>
          {{$t('WIZARD.REGISTER_COMPLETE')}}
        </template>
      </FlowItem>

    </div>
    <explain-block at="2.5vw">
      <div class="mx-3 my-2">
        <template v-if="status.companyInfoRegistered">
          <h2 class="font-weight-bold">{{$t('WIZARD.SALES_MANAGER_CHECK_DESC_TITLE')}}</h2>
          <p v-html="$t('WIZARD.SALES_MANAGER_CHECK_DESC')"></p>
        </template>
        <template v-else>
          <h2 class="font-weight-bold">{{$t('WIZARD.SALES_MANAGER_DESC_TITLE')}}</h2>
          <p v-html="$t('WIZARD.SALES_MANAGER_DESC')"></p>
        </template>
      </div>
    </explain-block>
  </div>
</template>

<script>
import FlowItem from './FlowItem.vue'
import ExplainBlock from '@/components/common/ExplainBlock.vue';
export default {
  props: ['status'],
  components: { FlowItem, ExplainBlock }
  
}
</script>

<style scoped lang="scss">
  .registration-flow-container {
    border: 1px solid #EDEDED;
    padding: 1rem 1.5rem 1rem 1.5rem;
    .flow {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      margin-top: 65px;
      margin-bottom: 65px;
      padding-left: 25px;
      padding-right: 25px;
      .divider {
        border-bottom: 4px solid #CCCCCC;
        flex-grow: 1; 
        &.dashed {
          border-bottom: 4px dashed #CCCCCC !important;
          &.double-next{
            border-top: 4px dashed #CCCCCC !important;
          }
          &:after{
            border-right: 4px dashed #CCCCCC !important;
          }
          &:before{
            border-left: 4px dashed #CCCCCC !important;
          }
        }
        &.double {
          height: 50px;
          margin-top: -45px;
          flex: 0.5;
          &:after {
            height: 100px;
            border-right: 4px solid #CCCCCC;
            display:block;
            content: '';
            margin-right: -4px;
          }
        }
        &.double-reverse {
          height: 50px;
          margin-top: -45px;
          flex: 0.5;
          &:before {
            height: 100px;
            border-left: 4px solid #CCCCCC;
            display:block;
            content: '';
            margin-left: -4px;
          }
        }
        &.double-next{
          height: 104px;
          flex: 0.5;
          border-top: 4px solid #CCCCCC;
          border-bottom: 4px solid #CCCCCC;
          margin-bottom: -4px;
        }
      }
      .multi-step {
        & > :not(:first-child) {
          margin-top: 54px;
        } 
      }
    }
    
  }
</style>