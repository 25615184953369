<template>
  <div class="page">
    <div class="sub-title">
      <h1>{{$t('WIZARD.PREPARATION_FLOW')}}</h1>
    </div>
    <InitiateFlow :status="status" v-if="!status.initChecked"/>
    
    <div class="mt-0">
      <router-view @onSave="onSubmit" v-bind="isRegistrationWizard"></router-view>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  import InitiateFlow from '@/components/flow/InitiateFlow.vue';
  import CompanyModel from '@/model/CompanyInfo.js';
  
  export default {
    components: {
      InitiateFlow
    },
    computed: {
      isRegistrationWizard(){
        return {
          isRegistrationWizard: true,
          notSave: true,
          formData: this.form
        }
      }
    },
    watch:{
      $route (to){
        switch(to.path){
          case "/initiate/registration/company-info": 
            this.status.companyInfoRegistered = false;
            break;
          case "/initiate/registration/confirmation": 
            this.status.initChecked = false;
            break;
          case "/initiate/registration/complete":
            this.status.initChecked=true;
            break;
        }
      }
    },
    data: function() {
      var data = {
        form: {
          company: {},
          main:{},
          subs:[]
        },
        status: {}
      };
      if(this.$route.query.corporationNumber){
        data.form.company.corporationNumber = this.$route.query.corporationNumber;
      }
      return data;
    },
    created: function(){
      CompanyModel.getCompanyStatus(this.$store.state.user.companyCode)
      .then(result => {
        this.status = result.data; 
        this.status.openSupplier = this.$route.query.open;
        console.log(this.$route.query);
        if(this.$route.path === "/initiate/registration"){
          this.$router.push({ name:"InitiateCompanyInfo", query: { open: this.$route.query.open } });
        }
        if(this.$route.path === "/initiate/registration/confirmation"){
          this.status.companyInfoRegistered = true;
          this.status.initChecked = false;
        }
        if(this.$route.path === "/initiate/registration/complete"){
          this.status.initChecked=true;
          this.status.initDone=true;
          
        }
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    methods: {
      onSubmit(data){
        this.topFunction();
        switch(this.$route.path){
          case "/initiate/registration/company-info":
            this.form = Object.assign(this.form, data);
            this.status.companyInfoRegistered = true;
            this.$router.push("/initiate/registration/confirmation");
            break;
        }
      },
      topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    }
  }
</script>