<template>
  <div class="p-4 bg-custom-gray" v-if="display">
    <template v-if="ask">
      <p style="text-align:right; float:right;">
        <a class="pointer" @click.prevent="display = false;">今後からヘルプを表示しない</a>｜
        <a class="pointer" @click.prevent="display = false;">またヘルプを表示する</a>
      </p>
    </template>
    <template v-else-if="hasClose">
      <a class="text-muted close-icon" @click.prevent="ask = true;"><font-awesome-icon icon="times"/></a>  
    </template>
    <slot></slot>
    <span class="triangle" :style="{'left': at}"></span>
  </div>
</template>

<script>
export default {
  props:["hasClose", "at"],
  data(){
    return {
      display: true,
      ask: false
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
  .close-icon{
    float:right;
    cursor:pointer;
  }
  .pointer {
    cursor:pointer;
  }

  .bg-custom-gray{
    background: #fff3cd;
    position: relative;
    border-radius: .4em;
    border: 1px solid #ffeeba;
    
  }
  .bg-custom-gray .triangle{
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #fff3cd transparent;
    border-width: 0 15px 25px;
    top: -25px;
    margin-left: -15px;
  }

</style>